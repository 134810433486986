import md5 from 'md5'
import { actions } from './index'
import axios from 'axios'

import { akulaku, helpers } from '@common'
import { store } from '@pc/store'
import { push } from 'connected-react-router'
import { globalActionCreator } from '@pc/common/globalActions'

import { Translate } from '@pc/config/translate'
import Logger, * as elementId from '@pc/config/logger'
import { Timer } from '@pc/config/Timer'
import loggerConfig from '../../login/logger.config'
import { getSmDeviceData } from '@/components/device-sdk'
import { setSecurityCacheData } from '@/components/Security/utils'

const { storage } = helpers
/*
 * 设置密码错误提示
 * errText {string} 错误提示语
 */
export const goSetPasswordErrorText = (errText) => ({
  type: actions.SET_PASSWORD_ERROR_TEXT,
  errText,
})

/*
 * 设置密码
 * password {string} 用户设置的密码
 */
export const goSetPassword = (password, curPeriod, callbackPageUrl) => {
  const saLogger = Logger.getInstance()
  const translate = Translate.getInstance().translate
  const state = store.getState()
  const countryId = state.getIn(['main', 'countryId'])
  const isWhiteList = state.getIn(['main', 'isWhiteList'])
  const phoneNumber = state.getIn(['register', 'phoneNumber'])
  const captcha = state.getIn(['register', 'captcha'])

  password = md5(password)

  return async (dispatch) => {
    if (isWhiteList) {
      dispatch(goRegister(phoneNumber, captcha, false, password))
      return
    }

    dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { success, errCode, errMsg },
    } = await axios.post('/installment/api/json/user/register/pwd/set.do', {
      password,
    })
    dispatch(globalActionCreator.toggleLoading(false))

    if (success) {
      dispatch(__savePrivateKey(password))
      if (countryId === 1) {
        dispatch(
          globalActionCreator.toggleToast(true, translate('注册成功'), 1.5),
        )
        setTimeout(() => {
          dispatch(
            push({
              pathname: '/applylimit-instruction',
              state: { curPeriod, callbackPageUrl },
              search: window.location.search,
            }),
          )
        }, 1500)
      } else {
        dispatch(toggleRegisterSuccess(true))
      }

      saLogger.onPageClick(
        elementId.SET_NEW_PWD_SUBMIT,
        elementId.STATUS_SET_NEW_PWD_PASS,
      )
    } else {
      saLogger.onPageClick(
        elementId.SET_NEW_PWD_SUBMIT,
        elementId.STATUS_SET_NEW_PWD_FAILED,
      )
      switch (errCode) {
        default:
          dispatch(globalActionCreator.toggleToast(true, errMsg))
          break
      }
    }
  }
}

/*
 * 设置验证码错误信息
 * errText {string} 错误信息
 */
export const goSetCodeErrorText = (errText) => ({
  type: actions.SET_CODE_ERROR_TEXT,
  errText,
})

/*
 * 设置手机号错误信息
 * errText {string} 错误信息
 */
export const goSetPhoneNumberErrorText = (errText) => ({
  type: actions.SET_PHONE_NUMBER_ERROR_TEXT,
  errText,
})

export const savePhoneNumber = (phoneNumber) => ({
  type: actions.SAVE_PHONE_NUMBER,
  phoneNumber,
})

export const saveCaptcha = (captcha) => ({
  type: actions.SET_CAPTCHA,
  captcha,
})

/*
 * 显示图形验证码弹框
 * show {bool} true代表显示，false代表隐藏
 */
export const goToggleGraphicDialog = (show) => ({
  type: actions.TOGGLE_GRAPHIC_DIALOG,
  show,
})

/*
 * 显示设置密码弹框
 * show {bool} true代表显示，false代表隐藏
 */
export const goToggleSetPasswordDialog = (show) => ({
  type: actions.TOGGLE_SET_PASSWORD_DIALOG,
  show,
})

/*
 * 改变验证码发送状态
 * sent {boolean} true代表验证码已发送，false代表未发送
 */
const __changeSendStatus = (sent) => ({
  type: actions.CHANGE_CODE_STATUS,
  sent,
})

/*
 * 改变countDown数值，用于显示验证码倒计时
 * time {number}
 */
const __changeCountDown = (time) => ({
  type: actions.CHANGE_COUNTDOWN,
  countDown: time,
})

/*
 * 设置图形验证码url
 */
const __setGraphicCaptchaUrl = (url) => ({
  type: actions.SET_GRAPHIC_CAPTCHA_URL,
  url,
})

/*
 * 显示注册成功
 */
export const toggleRegisterSuccess = (show) => ({
  type: actions.TOGGLE_REGISTER_SUCCESS,
  show,
})

/*
 * 显示授信引导
 */
export const showApplyInstruction = () => ({
  type: actions.SHOW_APPLY_INSTRUCTION,
  show: true,
})

/*
 * 获取图形验证码
 */
export const goGetGraphicCaptcha = () => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await axios.get('/api/json/public/image/captcha.do')
    dispatch(__setGraphicCaptchaUrl(`data:image/png;base64, ${data}`))
  }
}

/*
 * 设置图形验证码错误信息
 * errText {string} 错误信息
 */
export const goSetGraphicErrorText = (errText) => ({
  type: actions.SET_GRAPHIC_ERROR_TEXT,
  errText,
})

/**
 * 显示/隐藏安全组件
 */
export const toggleSecurityVerification = (securityVerificationVisible) => ({
  type: actions.TOGGLE_SECURITY_VERIFICATION,
  securityVerificationVisible,
})

/**
 * 设置安全组件临时参数
 * */

export const setSecurityVerificationTempParams = (
  securityVerificationTempParams,
) => ({
  type: actions.SET_SECURITY_VERIFICATION_TEMP_PARAMS,
  securityVerificationTempParams,
})

/*
 * 发送验证码——新
 */
const __sendSMSCodeNew = (params, saLogger, translate) => {
  const { phoneNumber } = params
  // FIXME: captchaType、userSource 字段是否还需要？待确定
  const postData = {
    account: phoneNumber,
    captchaType: 1, //注册
    userSource: 2, // 2 openpay
  }

  const state = store.getState()
  const riskFlowId = state.getIn(['login', 'operationId'])
  if (riskFlowId) postData.operationId = riskFlowId

  return async (dispatch) => {
    dispatch(__changeSendStatus(true))
    const {
      data: { success, errCode, errMsg },
    } = await axios.post(
      '/capi/openpay/v2/public/client/login/captcha',
      postData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (success) {
      //开始倒计时
      const smsTimer = Timer.create().register({
        name: 'smsTimer',
        callback: (count) => {
          dispatch(__changeCountDown(count))
        },
        endCallback: () => {
          dispatch(__changeSendStatus(false))
        },
        time: 60,
      })
      smsTimer.run()
      // console.log(`code:${data}`)
    } else {
      dispatch(__changeSendStatus(false))
      //TODO: 问后台要错误码
      switch (errCode) {
        case '10811410040410':
          dispatch(
            globalActionCreator.toggleAlertDialog(true, {
              title: translate('提示'),
              content: translate('账号已存在，请直接登录'),
              footer: translate('我知道了'),
            }),
          )
          break

        default:
          dispatch(globalActionCreator.toggleToast(true, errMsg))
          break
      }
    }
  }
}

/*
 * 发送验证码——老
 * phoneNumber {string}  手机号
 * imageCaptcha {string}  图形验证码
 */
const __sendSMSCodeOld = (params, saLogger, translate) => {
  const { phoneNumber, imageCaptcha, countryId, isGraphicShow } = params
  const state = store.getState()
  const riskFlowId = state.getIn(['login', 'operationId'])

  let postData = {
    countryId,
    phoneNumber,
  }

  if (imageCaptcha) {
    postData['imageCaptcha'] = imageCaptcha
  }

  if (riskFlowId) postData.riskFlowId = riskFlowId

  return async (dispatch) => {
    dispatch(__changeSendStatus(true))
    imageCaptcha && dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { success, data, errCode, errMsg },
    } = await axios.post('/api/json/public/user/openpay/register/captcha.do', {
      ...postData,
    })
    imageCaptcha && dispatch(globalActionCreator.toggleLoading(false))

    if (success) {
      //开始倒计时
      const smsTimer = Timer.create().register({
        name: 'smsTimer',
        callback: (count) => {
          dispatch(__changeCountDown(count))
        },
        endCallback: () => {
          dispatch(__changeSendStatus(false))
        },
        time: 60,
      })
      smsTimer.run()
      dispatch(goToggleGraphicDialog(false))

      imageCaptcha &&
        saLogger.onPageClick(
          elementId.CLICK_GRAPHIC_SUBMIT,
          elementId.STATUS_GRAPHIC_PASS,
          elementId.PAGE_REGISTER,
        )

      console.log(`code:${data}`)
    } else {
      dispatch(__changeSendStatus(false))
      //重新获取图形验证码
      dispatch(goGetGraphicCaptcha())

      switch (errCode) {
        //需要填写图形验证码
        case 'PROFILE.0054':
          dispatch(goToggleGraphicDialog(true))
          break

        //验证码错误
        case 'PROFILE.0003':
          isGraphicShow
            ? dispatch(
                goSetGraphicErrorText(translate('验证码错误，请重新输入')),
              )
            : dispatch(
                globalActionCreator.toggleToast(
                  true,
                  translate('验证码错误，请重新输入'),
                ),
              )
          break

        //手机号错误
        case 'PROFILE.0002':
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('请输入正确的手机号码'),
            ),
          )
          break

        //被拉入黑名单
        case 'CAPTCHA.0001':
          dispatch(goToggleGraphicDialog(false))
          dispatch(
            globalActionCreator.toggleAlertDialog(true, {
              title: translate('提示'),
              content: errMsg,
              footer: translate('我知道了'),
            }),
          )
          break

        default:
          dispatch(globalActionCreator.toggleToast(true, errMsg))
          break
      }

      imageCaptcha &&
        saLogger.onPageClick(
          elementId.CLICK_GRAPHIC_SUBMIT,
          elementId.STATUS_GRAPHIC_FAILED,
          elementId.PAGE_REGISTER,
        )
    }
  }
}

/*
 * 保存access token
 * 新注册流程需要用到
 */
const __setAccessToken = (token) => ({
  type: actions.SET_ACCESS_TOKEN,
  accessToken: token,
})

/*
 * 发送验证码
 * phoneNumber {string}  手机号
 * imageCaptcha {string}  图形验证码
 */
export const goSendCode = (phoneNumber, imageCaptcha, securityChecked) => {
  const translate = Translate.getInstance().translate
  const state = store.getState()
  const countryId = state.getIn(['main', 'countryId'])
  const isGraphicShow = state.getIn(['register', 'isGraphicShow'])
  const isWhiteList = state.getIn(['main', 'isWhiteList'])
  const saLogger = Logger.getInstance()

  return async (dispatch) => {
    if (!securityChecked) {
      dispatch(
        setSecurityVerificationTempParams({
          phoneNumber,
          countryId,
          imageCaptcha,
        }),
      )
      dispatch(toggleSecurityVerification(true))
      return
    }

    isWhiteList
      ? dispatch(
          __sendSMSCodeNew(
            { phoneNumber, imageCaptcha, countryId, isGraphicShow },
            saLogger,
            translate,
          ),
        )
      : dispatch(
          __sendSMSCodeOld(
            { phoneNumber, imageCaptcha, countryId, isGraphicShow },
            saLogger,
            translate,
          ),
        )
  }
}

/*
 * 提交短信验证码
 */
const __submitSMSCode = (params, saLogger, translate) => {
  return async (dispatch, getState) => {
    const { phoneNumber, countryId, captcha, isPc, password } = params
    const state = getState()
    const riskFlowId = state.getIn(['login', 'operationId'])
    const postData = {
      account: phoneNumber,
      otp: captcha,
      operationId: riskFlowId,
      agreeBindProtocol: true,
    }
    const showBindProtocol = state.getIn(['main', 'showBindProtocol'])
    const agreeBindProtocol = state.getIn(['login', 'agreeBindProtocol'])
    if (showBindProtocol) {
      postData.agreeBindProtocol = agreeBindProtocol
    }
    const smDeviceData = await getSmDeviceData()
    const {
      data: { success, data, errCode, errMsg },
    } = await axios.post(
      '/capi/openpay/v2/public/client/user/captcha/login',
      {
        smDeviceData,
        ...postData,
        orderId: helpers.URL.getParam('orderId') || '',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (success) {
      setSecurityCacheData({
        loginType: data?.loginType,
        loginTime: data?.loginTime,
        riskSign: data?.riskSign,
      })
      if (!data.hasNext) {
        return dispatch(
          globalActionCreator.toggleAlertDialog(true, {
            title: translate('提示'),
            content: translate('账号已存在，请直接登录'),
            footer: translate('我知道了'),
          }),
        )
      }

      dispatch(__setAccessToken(data.accessToken))
      dispatch(savePhoneNumber(phoneNumber))

      if (isPc) {
        dispatch(
          __registerNew(
            {
              phoneNumber,
              password,
              countryId,
              accessToken: data.accessToken,
              isPc,
            },
            saLogger,
            translate,
          ),
        )
      } else {
        saLogger.onPageClick(loggerConfig.set_password_popup)
        dispatch(goToggleSetPasswordDialog(true))
      }
    } else {
      switch (errCode) {
        case '10811410040416':
          dispatch(goSetCodeErrorText(translate('验证码错误，请重新输入')))

          saLogger.onPageClick(
            elementId.CLICK_REGISTER,
            elementId.STATUS_SMS_FAILED,
            elementId.PAGE_REGISTER,
          )
          break

        default:
          dispatch(globalActionCreator.toggleToast(true, errMsg))
      }
    }
  }
}

export const goRegister = (
  phoneNumber,
  captcha,
  isPc = false,
  password,
  confirmPassword,
) => {
  const translate = Translate.getInstance().translate
  const state = store.getState()
  const countryId = state.getIn(['main', 'countryId'])
  const appId = state.getIn(['main', 'appId'])
  const isWhiteList = state.getIn(['main', 'isWhiteList'])
  const accessToken = state.getIn(['register', 'accessToken'])
  const curPeriod = state.getIn(['login', 'curPeriod']) //这里为啥要从login拿数据呢？我也不记得了，可能存在问题
  const callbackPageUrl = state.getIn(['login', 'callbackPageUrl']) //按道理应该从当前页面的store里面拿
  const saLogger = Logger.getInstance()

  return (dispatch) => {
    const commonParams = {
      phoneNumber,
      password,
      captcha,
      isPc,
      curPeriod,
      callbackPageUrl,
      countryId,
      appId,
    }
    isWhiteList
      ? dispatch(
          __registerNew({ ...commonParams, accessToken }, saLogger, translate),
        )
      : dispatch(
          __registerOld(
            { ...commonParams, confirmPassword },
            saLogger,
            translate,
          ),
        )
  }
}

/*
 * 注册——新
 */
const __registerNew = (params, saLogger, translate) => {
  const {
    phoneNumber,
    countryId,
    password,
    accessToken,
    captcha,
    isPc,
    curPeriod,
    callbackPageUrl,
  } = params
  return async (dispatch, getState) => {
    const state = getState()
    //如果没传密码，则走提交验证码
    if (!password || (isPc && !accessToken)) {
      dispatch(
        __submitSMSCode(
          { countryId, phoneNumber, captcha, isPc, password },
          saLogger,
          translate,
        ),
      )
      return
    }
    dispatch(globalActionCreator.toggleLoading(true))
    const postData = {
      account: phoneNumber,
      password,
      accessToken,
      agreeBindProtocol: true,
    }
    const showBindProtocol = state.getIn(['main', 'showBindProtocol'])
    const agreeBindProtocol = state.getIn(['login', 'agreeBindProtocol'])
    if (showBindProtocol) {
      postData.agreeBindProtocol = agreeBindProtocol
    }
    const smDeviceData = await getSmDeviceData()
    const {
      data: { success, data, errCode, errMsg },
    } = await axios.post(
      '/capi/openpay/v2/public/client/user/register',
      {
        smDeviceData,
        ...postData,
        orderId: helpers.URL.getParam('orderId') || '',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    dispatch(globalActionCreator.toggleLoading(false))

    if (success) {
      setSecurityCacheData({
        loginType: data?.loginType,
        loginTime: data?.loginTime,
        riskSign: data?.riskSign,
      })

      if (isPc) {
        dispatch(
          globalActionCreator.toggleToast(true, translate('注册成功'), 1.5),
        )
        setTimeout(() => {
          dispatch(
            push({
              pathname: '/pc/applylimit-instruction',
              state: { curPeriod, callbackPageUrl },
              search: window.location.search,
            }),
          )
        }, 1500)
      } else {
        dispatch(__savePrivateKey(password))
        if (countryId === 1) {
          dispatch(
            globalActionCreator.toggleToast(true, translate('注册成功'), 1.5),
          )
          setTimeout(() => {
            dispatch(
              push({
                pathname: '/applylimit-instruction',
                state: { curPeriod, callbackPageUrl },
                search: window.location.search,
              }),
            )
          }, 1500)
        } else {
          dispatch(toggleRegisterSuccess(true))
        }
      }
    } else {
      switch (errCode) {
        case '10811410040410':
          dispatch(
            globalActionCreator.toggleAlertDialog(true, {
              title: translate('提示'),
              content: translate('账号已存在，请直接登录'),
              footer: translate('我知道了'),
            }),
          )
          break
        default:
          dispatch(globalActionCreator.toggleToast(true, errMsg))

          break
      }
      saLogger.onPageError(elementId.ERROR_REGISTER_FAIL, errMsg)
    }
  }
}

/*
 * 注册——老
 * phoneNumber {string}  手机号
 * captcha     {string} 短信验证码
 * isPc {bool} 是否是PC端
 * password {string}
 * confirmPassword {string}
 */
const __registerOld = (params, saLogger, translate) => {
  const {
    phoneNumber,
    countryId,
    password,
    confirmPassword,
    isPc,
    curPeriod,
    callbackPageUrl,
    appId,
    captcha,
  } = params

  //移动端和PC端注册接口不同，入参也不同
  const url = isPc
    ? '/api/json/public/user/openpay/pc/register.do'
    : '/api/json/public/user/openpay/register.do'
  let postData = {
    countryId,
    appId,
    phoneNumber,
    captcha,
    registerAuthSource: 0, //TODO: 区分BL平台}
  }

  return async (dispatch) => {
    if (isPc) {
      postData = {
        ...postData,
        password: md5(password),
        confirmPassword: md5(confirmPassword),
      }
      dispatch(__savePrivateKey(postData.password))
    }

    dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { success, errCode, errMsg },
    } = await axios.post(url, postData)

    if (success) {
      const userInfo = await akulaku.updateUserInfo()
      userInfo && saLogger.setUid(userInfo.uid)
      userInfo && saLogger.login(userInfo.uid)

      dispatch(globalActionCreator.toggleLoading(false))

      saLogger.onPageClick(
        elementId.CLICK_REGISTER,
        elementId.STATUS_REGISTER_SUCCESS,
        elementId.PAGE_REGISTER,
      )

      if (isPc) {
        // if (countryId === 1) {
        dispatch(
          globalActionCreator.toggleToast(true, translate('注册成功'), 1.5),
        )
        setTimeout(() => {
          dispatch(
            push({
              pathname: isPc
                ? '/pc/applylimit-instruction'
                : '/applylimit-instruction',
              state: { curPeriod, callbackPageUrl },
              search: window.location.search,
            }),
          )
        }, 1500)
        // } else {
        //   dispatch(toggleRegisterSuccess(true))
        // }
      } else {
        dispatch(goToggleSetPasswordDialog(true))
      }
      dispatch(savePhoneNumber(phoneNumber))
    } else {
      dispatch(globalActionCreator.toggleLoading(false))
      saLogger.onPageError(elementId.ERROR_REGISTER_FAIL, errMsg)
      switch (errCode) {
        //老用户去登录
        case 'PROFILE.0005':
          dispatch(
            globalActionCreator.toggleAlertDialog(true, {
              title: translate('提示'),
              content: translate('账号已存在，请直接登录'),
              footer: translate('我知道了'),
            }),
          )
          break

        //用户未设置密码，直接弹出密码输入框
        case 'PROFILE.0018':
          dispatch(goToggleSetPasswordDialog(true))
          dispatch(savePhoneNumber(phoneNumber))

          break

        //验证码错误
        case 'PROFILE.0003':
        case 'PROFILE.0045':
          dispatch(goSetCodeErrorText(translate('验证码错误，请重新输入')))

          saLogger.onPageClick(
            elementId.CLICK_REGISTER,
            elementId.STATUS_SMS_FAILED,
            elementId.PAGE_REGISTER,
          )
          break

        default:
          dispatch(globalActionCreator.toggleToast(true, errMsg))
      }
    }
  }
}

//保存用户登录密码到session，下单接口需要传
const __savePrivateKey = (key) => {
  return () => {
    storage.setSession('privateKey', key)
  }
}
